// src/App.js
import React from 'react';
import './App.css';

function App() {
  return (
    <div className="maintenance-container">
      <div className="icon-container">
        {/* Wrench Icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="icon"
        >
          <path
            fill="currentColor"
            d="M22.7 19.3l-5.2-5.2c-.4-.4-.8-.4-1.2 0l-1.6 1.6c-.4.4-.4.8 0 1.2l5.2 5.2c.4.4.8.4 1.2 0l1.6-1.6c.4-.4.4-.8 0-1.2zM14 0c-1.7 0-3.2.7-4.2 1.8L7.9 3.7c-.7.7-.8 1.8-.3 2.6L10 10c-.3.5-.7 1-.9 1.5-1.1 2.1-3.5 3-5.7 2.1l-1-.4c-.8-.3-1.6 0-2 .8C.1 15.3 0 15.8 0 16.2v1.3c0 .6.5 1.1 1.1 1 1.4-.2 3.5-.2 5.6-1.4 2.1-1.1 3.3-3.1 4.4-5.3.1-.2.2-.3.3-.5l1.8 1.8c.7.7 1.8.8 2.6.3l1.9-1.9c1.1-1.1 1.8-2.6 1.8-4.2 0-3.3-2.7-6-6-6zm0 4c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
          />
        </svg>
        <h1>Site Under Maintenance</h1>
      </div>
      <p>We're currently performing scheduled maintenance.</p>
      <p>Thank you for your patience. We'll be back soon!</p>
      <div className="footer-icons">
        {/* Warning Icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="footer-icon"
        >
          <path
            fill="currentColor"
            d="M1.5 22h21c.8 0 1.3-.8 1-1.5l-10.5-19C12.5.4 12.3 0 12 0s-.5.4-.8 1.5L.7 20.5c-.3.7.3 1.5 1.1 1.5zm10.5-5c-.6 0-1-.4-1-1v-4c0-.6.4-1 1-1s1 .4 1 1v4c0 .6-.4 1-1 1zm0 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
          />
        </svg>
      </div>
    </div>
  );
}

export default App;
